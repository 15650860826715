<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('approvalProcess.application_request_list') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                    <b-form-group
                      label-for="service_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('teaGardenConfig.service_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.service_id"
                      :options="masterServiceList"
                      id="service_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                    label-for="applicant_list"
                  >
                    <template v-slot:label>
                      {{ $t('teaGardenService.application_id') }}
                    </template>
                    <b-form-input
                        id="app_id"
                        type="text"
                        v-model="search.app_id"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- application type -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Application Type" vid="application_type" rules="">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="application_type">
                            <template v-slot:label>
                                {{ $t('teaGardenPanel.application_type') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.application_type"
                                id="application_type"
                                :options="applicationType"
                                :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                        label-for="applicant_company"
                      >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.applicant_company') }}
                        </template>
                        <b-form-input
                            id="applicant_company"
                            type="text"
                            v-model="search.company_name"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                        label-for="mobile"
                      >
                        <template v-slot:label>
                          {{ $t('globalTrans.mobile') }}
                        </template>
                        <b-form-input
                            id="mobile"
                            type="text"
                            v-model="search.mobile"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="">
                        <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.fiscal_year') }}
                          </template>
                          <b-form-select
                            plain
                            id="fiscal_year_id"
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- from date -->
                    <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="Start date" vid="from_date" v-slot="{ errors }" :rules="{required: false}">
                        <b-form-group
                          label-for="from_date">
                          <template v-slot:label>
                            {{ $t('globalTrans.start_date') }}
                          </template>
                          <date-picker
                            id="from_date"
                            v-model="search.from_date"
                            class="form-control"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </date-picker>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- to date -->
                    <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="To date" vid="to_date" v-slot="{ errors }" :rules="{required: false}">
                        <b-form-group
                          label-for="to_date">
                          <template v-slot:label>
                            {{ $t('globalTrans.to_date') }}
                          </template>
                          <date-picker
                            id="to_date"
                            v-model="search.to_date"
                            class="form-control"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </date-picker>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                      <b-button size="sm" type="submit" variant="primary" class="mt-20">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title"> {{ $t('approvalProcess.application_request_list') }}</h4>
      </template>
      <template v-slot:headerAction>
          <a href="javascript:" class="btn-add mr-1" @click="exportPdf">
              {{ $t('globalTrans.export_pdf') }}
          </a>
        </template>
       <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(remarks)="data">
                    <slot v-if="data.item.forwards">
                      {{ data.item.forwards[0].remarks}}
                    </slot>
                  </template>
                  <template v-slot:cell(issue_date)="data">
                    <slot v-if="data.item.issue_date">
                      {{ data.item.issue_date| dateFormat }}
                    </slot>
                  </template>
                  <template v-slot:cell(application_type)="data">
                    {{ getApplicationType(data.item.application_type) }}
                  </template>
                  <template v-slot:cell(applicant_name)="data">
                    <span v-if="data.item.taggable">
                      {{ $i18n.locale === 'en' ? data.item.taggable.applicant_name_en : data.item.taggable.applicant_name_bn }}
                    </span>
                  </template>
                  <template v-slot:cell(company_name)="data">
                    <span v-if="data.item.taggable">
                      <span v-if="data.item.service_id == 4">
                        {{ $i18n.locale === 'en' ? data.item.taggable?.factory_info?.factory_name_en : data.item.taggable?.factory_info?.factory_name_bn }}
                      </span>
                      <span v-else>
                        {{ $i18n.locale === 'en' ? data.item.taggable?.company_name_en : data.item.taggable?.company_name_bn }}
                      </span>
                    </span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <!-- <span class="badge badge-info" v-if="data.item.status === 3">{{$t('globalTrans.forwarded_to')}} {{ ($i18n.locale === 'bn') ? data.item.forwards[0].receiver_name_bn : data.item.forwards[0].receiver_name }}</span> -->
                    <span class="badge badge-info">{{$t('globalTrans.forwarded_to')}} {{ forwardedDesignationName(data.item.forwards[0]) }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link class="moc-action-btn moc-view-btn" :title="$t('globalTrans.details')" :to="`/tea-garden-service/approval-process/applicant-list/detail/${data.item.service_id}/${data.item.taggable_id}`">
                      <i class="ri-eye-line"></i>
                    </router-link>
                    <b-button class="moc-action-btn moc-assign-btn" v-if="data.item.status === 3" @click="submit(data.item)" :title="$t('teaGardenBtriService.assign')" v-b-modal.details>
                      <i class="ri-send-plane-fill"></i>
                    </b-button>
                    <b-button class="moc-action-btn moc-forward-btn" :title="$t('globalTrans.forward')" v-b-modal.modal-5 @click="recommended(data.item)">
                      <i class="ri-arrow-go-forward-fill"></i>
                    </b-button>
                      <!-- payment receipt  -->
                    <b-button v-b-modal.modal-6 class="moc-action-btn moc-payment-receipt-btn" :title="$t('externalLrcpn.payment_receipt')" @click="paymentInfoShow(data.item)" v-if="data.item.payment_status === 1 && (data.item.payment)">
                      <i class="ri-file-shield-2-line"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('globalTrans.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
      <Forward :id="recommendedItemId" :service_id="service_id" hide-footer/>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="$t('externalLrcpn.payment_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
      <template #modal-title class="flex-grow-1">
        {{ $t('externalLrcpn.payment_receipt') }}
        <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <PaymentInfo :id="item.id" :item="item" ref="paymentInfo"/>
    </b-modal>
    <!-- <pre>{{listData}}</pre> -->
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { applicantForwardList, applicantToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Forward from './../applicant-list/Forward'
import PaymentInfo from '../../../btb/pages/Payment/PaymentInfo.vue'

export default {
  mixins: [ModalBaseMasterList],
 components: {
    Forward, PaymentInfo
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      search: {
        service_id: 0,
        fiscal_year_id: 0,
        employee_user_id: 0,
        application_type: 0,
        status: 0,
        limit: 10
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'teaGardenConfig.service_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenPanel.owner_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'teaGardenPanel.applicant_company', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'teaGardenService.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'teaGardenPanel.application_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
        { labels: 'teaGardenPanel.application_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '5%' } },
        { labels: 'teaGardenConfig.remarks', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 10, thStyle: { width: '12%' } }
      ],
      actions: {
        edit: true,
        delete: false
      },
      item: {
        id: 0
      },
      recommendedItemId: '',
      service_id: ''
    }
  },
  created () {
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    statusList () {
      return [
        { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Recommendation' : 'সুপারিশ', text_en: 'Recommendation', text_bn: 'সুপারিশ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Recommended' : 'সুপারিশকৃত', text_en: 'Recommended', text_bn: 'সুপারিশকৃত' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
      ]
    },
    masterServiceList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(1) && item.value !== 19 && item.value !== 20 && item.value !== 21 && item.value !== 24 && item.value !== 25 && item.value !== 26 && item.value !== 36)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'service_bn' },
          { key: 'applicant_name' },
          { key: 'company_name' },
          { key: 'app_id' },
          { key: 'issue_date' },
          { key: 'application_type' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'service_en' },
          { key: 'applicant_name' },
          { key: 'company_name' },
          { key: 'app_id' },
          { key: 'issue_date' },
          { key: 'application_type' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).sort((a, b) => b.sorting_order - a.sorting_order)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('approvalProcess.application_request_list') + ' ' + this.$t('globalTrans.entry') : this.$t('approvalProcess.application_request_list') + ' ' + this.$t('globalTrans.modify')
    },
    applicantList () {
      return this.$store.state.TeaGardenService.commonObj.masterapplicantList.filter(item => item.status === 1)
    },
    applicationType () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'নতুন নিবন্ধন' : 'New Registration' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'নবায়ন' : 'Renew' }
      ]
    }
  },
  mounted () {
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        employee_user_id: this.$store.state.Auth.authUser.user_id
      })
    }
    this.loadData()
  },
  methods: {
    submit (item) {
      window.vm.$swal({
        title: window.vm.$t('approvalProcess.assign_yes_no'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(teaGardenServiceBaseUrl, `${applicantToggle}/${item.id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: window.vm.$t('globalTrans.success'),
                message: window.vm.$t('globalTrans.update_msg'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        }
      })
    },
    remove (item) {
      this.changeStatus(5, teaGardenServiceBaseUrl, applicantToggle, item)
    },
    recommended (item) {
      this.recommendedItemId = item.id
      this.service_id = item.service_id
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(3, teaGardenServiceBaseUrl, applicantToggle, item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, applicantForwardList, params)
      if (result.success) {
      const listData = result.data.data.map((item, index) => {
        const serviceObj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(law => law.value === parseInt(item.service_id))
        const serviceData = {}
          if (typeof serviceObj !== 'undefined') {
            serviceData.service_en = serviceObj.text_en
            serviceData.service_bn = serviceObj.text_bn
          } else {
            serviceData.service_en = ''
            serviceData.service_bn = ''
          }
        return Object.assign({}, item, { serial: index + 0 }, serviceData)
      })
      this.$store.dispatch('setList', listData)
      this.paginationData(result.data, this.search.limit)
    } else {
      this.$store.dispatch('setList', [])
      this.paginationData([])
    }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getApplicationType (typeId) {
        const type = this.applicationType.find(item => item.value === typeId)
        if (type !== undefined) {
          return type.text
        } else {
          return ''
        }
    },
    paymentInfoShow (item) {
      this.item = item
    },
    pdfExport () {
      this.$refs.paymentInfo.pdfExport()
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSubDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.subDesignationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    forwardedDesignationName (forward) {
      if (forward.sub_designation_id) {
        return this.getSubDesignationName(forward.sub_designation_id)
      } else if (forward.designation_id) {
        return this.getDesignationName(forward.designation_id)
      }
    },
    async exportPdf () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, applicantForwardList, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
